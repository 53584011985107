<template>
  <div class="animated fadeIn container">
    <h1>直接输入电话号码发送或者通过<br />文件输入批量号码发送</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount }}条 可发送条数</h2>
    <div class="row mt-4">
      <div class="col-4">
        <div class="phone-wrap">
          <textarea class="inner-text" id="message" name="body" rows="4" v-model="message" placeholder="请输入文字信息"
            v-on:input="updateMessage" required @keydown.enter.shift.exact.prevent="createSmsRequest"></textarea>
          <span class="color-skyblue inner-bytes">{{ contentBytes + 1 }}/{{ maxContentBytes }}</span>
        </div>
      </div>
      <div class="col-8">
        <table class="border-table">
          <!--
          <tr>
            <td width="190px">
              <img src="/img/cm.png" style="width: 132px" />
            </td>
            <td>

            </td>
          </tr>
          <tr>
            <td width="190px">
              <img src="/img/cu.png" style="width: 132px" />
            </td>
            <td>
              <label for="providerId1" class="mr-3">
                <input type="radio" name="providerId" id="providerId1" v-model="providerId" value="1" />
                <img src="/img/flag/world.png" class="ml-1" />
                90
              </label>
              <label for="providerId2" class="mr-3">
                <input type="radio" name="providerId" id="providerId2" v-model="providerId" value="2" />
                <img src="/img/flag/852.png" class="ml-1" />
                852
              </label>
              <label for="providerId3" class="mr-3">
                <input type="radio" name="providerId" id="providerId3" v-model="providerId" value="3" />
                <img src="/img/flag/1.png" class="ml-1" style="width:30px" />
                001
              </label>
            </td>
          </tr>
          <tr>
            <td width="190px">
              <img src="/img/ct.png" style="width: 132px" />
            </td>
            <td>
              <label for="providerId4" class="mr-3">
                <input type="radio" name="providerId" id="providerId4" v-model="providerId" value="4" />
                <img src="/img/flag/79.png" class="ml-1" />
                79
              </label>
            </td>
          </tr>
          -->
          <tr>
            <td colspan="2" style="padding-left: 195px">
              <label for="providerId5" class="mr-3" v-show="showProvider(5)">
                <input type="radio" name="providerId" id="providerId5" v-model="providerId" value="5" />
                <img src="/img/flag/81.png" class="ml-1" style="height: 30px" />
                81
              </label>
              <label for="providerId2" class="mr-3">
                <input type="radio" name="providerId" id="providerId2" v-model="providerId" value="2" />
                <img src="/img/flag/852.png" class="ml-1" />
                852
              </label>
            </td>
          </tr>
          <tr>
            <td width="190px">接受号码</td>
            <td>
              <textarea v-model="recipients" :style="recipientsStyle" class="recipient-number" rows="4" required
                @focus="focusRecipientsInput" @blur="blurfRecipientsInput"
                @keydown.enter.shift.exact.prevent="createSmsRequest"></textarea>
            </td>
          </tr>
          <tr>
            <td>输入文件</td>
            <td>
              <input ref="excel" type="file" id="excel" name="excel" accept=".xlsx,.txt,.csv"
                @change="handleFileChange" />
              <span v-if="files && files.length > 0" style="line-height: 29px">{{ files[0].name }}</span>
              <span v-else style="line-height: 29px">没有选择的文件</span>
              <button class="btn-primary float-right btn-rounded-small" @click="clickCustomFileBtn">选择文件</button>
            </td>
          </tr>
          <tr>
            <td>预约发送</td>
            <td>
              <b-form-checkbox type="checkbox" v-model="useReservation">使用预约发送</b-form-checkbox>
              <datetime v-show="useReservation" class="reservation-datetime" type="datetime" v-model="reservationDate"
                :min-datetime="minReservationDate" :max-datetime="maxReservationDate" :minute-step="5"
                :phrases="{ ok: '确认', cancel: '取消' }" placeholder="选择日期" zone="Asia/Shanghai" use12-hour />
            </td>
          </tr>
        </table>

        <b-btn ref="sendBtn" variant="primary" class="float-left btn-primary send-btn" @click="createSmsRequest"
          :disabled="isLoading">
          <span v-if="!isLoading">发送</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
        <div class="mt-2">
          <a href="/assets/sample.txt" style="color: #a0a0a0; text-decoration: underline; float: right"
            download="">TXT样品</a>
          <a href="/assets/sample.xlsx"
            style="color: #a0a0a0; text-decoration: underline; float: right; margin-right: 20px" download="">Excel样品</a>
        </div>
      </div>
    </div>
    <b-modal ref="senderRegisterModal" title="발신번호 등록" hide-footer="true" centered>
      <div style="display: flex; flex-flow: row">
        <input placeholder="전화번호" class="form-control" style="flex-grow: 1" v-model="generateSenderNumber"
          @keyup="generateSenderNumberKeyUp" />
        <button class="ml-1 btn-primary btn btn-small" @click="clickSendAuthCode" style="min-width: 100px"
          :disabled="senderAuthCodeSecs < 120">
          코드 전송
        </button>
      </div>
      <div>
        <input id="senderAuthCodeInput" v-model="senderAuthCode" type="text" class="form-control mt-2"
          placeholder="문자로 받은 4자리 코드를 입력해주세요" style="width: 100%" />
        <span class="sender-auth-code-time" v-show="senderAuthCodeSecs < 120">
          {{ senderAuthCodeSecs | timeFormat }}
        </span>
      </div>
      <div>
        <button class="mt-2 btn btn-primary w100" @click="clickCheckSenderAuthCode()" style="width: 100%">
          발신번호 등록
        </button>
      </div>
    </b-modal>
    <b-modal ref="senderManageModal" title="발신번호 관리" hide-footer="true" centered>
      <ul class="sender-number-list">
        <li v-for="item in senders" :key="item.id">
          <div>
            {{ item.phoneNumber | phoneFormat }}
            <span class="float-right">{{ item.sentCount | numberFormat }}条 发送</span>
          </div>
          <button class="btn btn-danger btn-small ml-2" @click="deleteSender(item.id)">删除</button>
        </li>
      </ul>
    </b-modal>
    <b-modal ref="sendCompleteModal" title="发送成功" ok-only ok-title="确认" centered>
      发送: <span class="color-skyblue">{{ sendResponse.totalCount }}</span> 条<br />
    </b-modal>
  </div>
</template>
<script>
import ContactService from '@/services/ContactService'
import SenderService from '@/services/SenderService'
import SmsService from '@/services/SmsService'
import { Settings as LuxonSettings } from 'luxon'
import { Datetime } from 'vue-datetime'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'

LuxonSettings.defaultLocale = 'zh-cn'

export default {
  name: 'SmsSend',
  data() {
    return {
      generateSenderNumber: '',
      senderAuthCode: '',
      senders: [],
      providerId: 0,
      senderNumber: '',
      senderAuthCodeSecs: 120,
      senderAuthCodeTimer: null,
      chartSpeed: null,
      availableSendCount: 0,
      recipientsPlaceholder: '电话号码1\n电话号码2\n电话号码3\n...',
      recipients: '电话号码1\n电话号码2\n电话号码3\n...',
      recipientsStyle: {
        color: '#999',
      },
      fixRecipients: '电话号码1\n电话号码2\n电话号码3\n...',
      fixRecipientsStyle: {
        color: '#999',
      },
      message: '',
      isLoading: false,
      loaderStyle: {
        color: 'rgba(255, 255, 255, 0.7)',
        size: '8px',
      },
      files: null,
      sendResponse: {
        totalCount: '',
        paidMoney: '',
        holdMoney: '',
      },
      gaugeOptions: {
        chart: {
          type: 'solidgauge',
        },
        title: null,
        pane: {
          center: ['50%', '85%'],
          size: '100%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (window.Highcharts.theme && window.Highcharts.theme.background2) || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0.2, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.8, '#DF5353'], // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70,
          },
          labels: {
            y: 16,
          },
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
      },
      useReservation: false,
      useFixNumber: false,
      reservationDate: null,
      minReservationDate: null,
      maxReservationDate: null,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getUserInfo()
    this.getContactList()
    this.getSenderList()
  },
  destroyed() {
    clearInterval(this.senderAuthCodeTimer)
  },
  components: {
    PulseLoader,
    Datetime,
  },
  created() {
    this.minReservationDate = new Date().toISOString()

    var maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 7)
    this.maxReservationDate = maxDate.toISOString()
  },
  methods: {
    showProvider() {
      return true;
    },
    clickManageSender() {
      this.$refs.senderManageModal.show()
    },
    clickRegisterSender() {
      this.senderAuthCodeSecs = 120
      this.generateSenderNumber = ''
      this.senderAuthCode = ''
      this.$refs.senderRegisterModal.show()
    },
    clickSendAuthCode() {
      const t = this
      SenderService.create({
        phoneNumber: this.generateSenderNumber,
      }).then(function (response) {
        if (response.status !== 200) {
          return
        }
        clearInterval(t.senderAuthCodeTimer)
        t.senderAuthCodeTimer = setInterval(function () {
          if (t.senderAuthCodeSecs < 0) {
            t.senderAuthCodeSecs = 120
            clearInterval(t.senderAuthCodeTimer)
            return
          }

          t.senderAuthCodeSecs--
        }, 1000)
        document.getElementById('senderAuthCodeInput').focus()
      })
    },
    async clickCheckSenderAuthCode() {
      const response = await SenderService.checkAuth(this.generateSenderNumber, this.senderAuthCode)
      if (response.status !== 200) {
        return
      }
      alert('등록되었습니다')
      clearInterval(this.senderAuthCodeTimer)
      this.$refs.senderRegisterModal.hide()
      this.getSenderList()
    },
    async getSenderList() {
      var response = await SenderService.list()
      if (response.status !== 200) {
        return
      }
      this.senders = response.data.list || []
      var senderNumbers = []
      for (var sender of this.senders) {
        senderNumbers.push(sender.phoneNumber)
      }
      this.senderNumbers = senderNumbers

      if (senderNumbers.length > 0) {
        this.senderNumber = senderNumbers[0]
      } else {
        this.senderNumber = ''
      }
    },
    async deleteSender(id) {
      if (!confirm('해당 발신번호를 삭제하시겠습니까?')) {
        return
      }

      var response = await SenderService.delete(id)
      if (response.status !== 200) {
        return
      }
      this.getSenderList()
    },
    generateSenderNumberKeyUp() {
      var number = this.generateSenderNumber.replace(/[^0-9]/g, '')
      var phone = ''
      if (number.length < 4) {
        phone = number
      } else if (number.length < 7) {
        phone += number.substr(0, 3)
        phone += '-'
        phone += number.substr(3)
      } else if (number.length < 11) {
        phone += number.substr(0, 3)
        phone += '-'
        phone += number.substr(3, 3)
        phone += '-'
        phone += number.substr(6)
      } else {
        phone += number.substr(0, 3)
        phone += '-'
        phone += number.substr(3, 4)
        phone += '-'
        phone += number.substr(7)
      }
      this.generateSenderNumber = phone
    },
    parseRecipient(recipients) {
      recipients = recipients.trim()
      recipients = recipients.replace(/\n\n+/g, '\n')
      recipients = recipients.replace(/[ \t\r]+/g, '')
      recipients = recipients.replace(/\n/g, ',')

      return recipients
    },
    createSmsRequest() {
      var recipients = ''
      if (this.recipients != this.recipientsPlaceholder) {
        recipients = this.parseRecipient(this.recipients)
      }

      var fixRecipients = ''
      if (this.useFixNumber && this.fixRecipients != this.recipientsPlaceholder) {
        fixRecipients = this.parseRecipient(this.fixRecipients)
      }

      if (this.message.length < 0) {
        alert('need message')
        return
      }
      // else if (!this.user.useLong && this.message.length > 69) {
      //   alert('max length is 70')
      //   return
      // }

      var formData = new FormData()
      formData.append('providerId', this.providerId)
      formData.append('message', this.message)
      if (recipients.length > 0) {
        formData.append('recipients', recipients)
      }

      if (fixRecipients.length > 0) {
        formData.append('fixRecipients', fixRecipients)
      }

      if (this.useReservation) {
        if (this.reservationDate.length == 0) {
          alert('need reservation date')
          return
        }
        formData.append('reservationDate', this.reservationDate)
      }
      var files = this.$refs.excel.files
      if (files && files.length > 0) {
        formData.append('excel', files[0])
      }

      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const t = this
      SmsService.createSmsRequest(formData)
        .then(function (response) {
          if (response.status !== 200) {
            return
          }
          t.sendResponse = response.data
          t.getUserInfo()
          t.clear()
          t.$refs.sendCompleteModal.show()
          setTimeout(function () {
            t.isLoading = false
          }, 200)
        })
        .catch(function () {
          t.isLoading = false
        })
    },
    handleFileChange() {
      this.files = this.$refs.excel.files
    },
    clickCustomFileBtn() {
      document.getElementById('excel').click()
    },
    enterRecipientsInput(e) {
      console.log(e)
    },
    focusRecipientsInput(e) {
      const t = e.target
      t.style.color = '#333'
      if (this.recipientsPlaceholder == t.value) {
        t.value = ''
      }
    },
    blurfRecipientsInput(e) {
      const t = e.target
      if (t.value.length == 0) {
        t.value = this.recipientsPlaceholder
        t.style.color = '#999'
      }
    },
    updateMessage(e) {
      this.message = e.target.value
    },
    clear() {
      this.recipients = this.recipientsPlaceholder
      this.recipientsStyle = {
        color: '#999',
      }
      this.files = null
      this.$refs.excel.value = null
    },
    async getContactList() {
      try {
        const response = await ContactService.list()
        const list = response.data.list

        if (list.length > 0) {
          this.fixRecipients = list[0].recipients.replace(/,/g, '\n')
          this.fixRecipientsStyle = { color: '#333' }
        }
      } catch (e) {
        // Do nothing
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    contentBytes: function () {
      var message = this.message
      return message.length
    },
    maxContentBytes: function () {
      return 140
      // if (!this.user) {
      //   return 70
      // }
      // return this.user.useLong ? 140 : 70
    },
    providerFee: function () {
      const providerId = this.providerId
      var smsFee = 1

      if (providerId == 1) {
        smsFee = 1
      }

      return smsFee
    },
  },
  watch: {
    user() {
      this.availableSendCount = parseInt(this.user.money / this.providerFee)
    },
    providerId() {
      this.availableSendCount = parseInt(this.user.money / this.providerFee)
    },
    isLoading() {
      this.$refs.sendBtn.disabled = this.isLoading
    },
    maxContentBytes() { },
    contacts() { },
    senders() { },
    senderNumber() { },
    senderAuthCodeSecs() { },
  },
}
</script>

<style scoped lang="scss">
.border-table {
  border-bottom: 1px solid #ebebeb;
}

#sms-send-loader {
  display: block;
  margin: 20px auto;
}

.phone-wrap {
  position: relative;
  width: 256px;
  height: 523px;
  background: url('/img/phone.jpg') center no-repeat;
}

.phone-wrap .inner-text {
  position: absolute;
  top: 65px;
  left: 18px;
  width: 220px;
  bottom: 65px;
  padding: 20px 15px;
  color: #000;
  line-height: 20px;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  letter-spacing: 1;
}

.phone-wrap .inner-bytes {
  position: absolute;
  right: 33px;
  bottom: 73px;
}

.recipient-number {
  border: none;
  outline: none;
  width: 100%;
  height: 100px;
}

#excel {
  display: none;
}

.send-btn {
  display: block;
  margin-top: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 110px;
  height: 40px;
  border-radius: 0.25rem;
}

#server-status-sub-msg {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

#container-speed {
  float: left;
  width: 240px;
  height: 240px;
}

.reservation-datetime {
  margin-top: 10px;
  width: 190px;
}

.vdatetime-input {
  padding-left: 10px;
  width: 100%;
  text-align: center;
}

.sender-number-list>li {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.sender-number-list>li>div:first-of-type {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-rounded-small {
  padding: 3px 10px;
  border-radius: 0.25rem;
}

.sender-auth-code-time {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  color: #20a8d8;
  opacity: 0.8;
}
</style>
